export default {
  "sun-country-on-the-murray": {
    x: 394.5,
    y: 353,
    slug: "sun-country-on-the-murray"
  },
  canberra: {
    x: 978.5,
    y: 184.5,
    slug: "canberra"
  },
  illawarra: {
    x: 1176.5,
    y: 78.5,
    slug: "illawarra"
  },
  gippsland: {
    x: 512,
    y: 646.4,
    width: 690,
    slug: "gippsland"
  },
  shoalhaven: {
    x: 1138.5,
    y: 194.23,
    slug: "shoalhaven"
  },
  "sapphire-coast": {
    x: 1041.5,
    y: 528.2,
    slug: "sapphire-coast"
  },
  "phillip-island": {
    x: 457,
    y: 950.5,
    width: 105,
    slug: "phillip-island"
  },
  "greater-bendigo": {
    x: 225.5,
    y: 521.67,
    slug: "greater-bendigo"
  },
  "echuca-moama": {
    x: 257.5,
    y: 386.26,
    slug: "echuca-moama"
  },
  "albury-wodonga": {
    x: 518.5,
    y: 375.74,
    slug: "albury-wodonga"
  },
  "greater-melbourne": {
    x: 390.5,
    y: 796.88,
    width: 225,
    slug: "greater-melbourne"
  },
  "greater-sydney": {
    x: 1298.5,
    y: 48.97,
    width: 225,
    slug: "greater-sydney"
  },
  eurobodalla: {
    x: 1084.5,
    y: 355.75,
    slug: "eurobodalla"
  }
};
