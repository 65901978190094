import Vue from 'vue'
import App from './App.vue'
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-boost';
import { VueHammer } from 'vue2-hammer'

Vue.config.productionTip = false

Vue.use(VueHammer)
Vue.use(VueApollo);

const site_url = typeof pageInfo !== 'undefined' ? pageInfo.site_url : process.env.VUE_APP_GRAPHQL_HTTP
// const site_url = process.env.VUE_APP_GRAPHQL_HTTP

//creating apollo client
const client = new ApolloClient({
  uri: site_url + '/wp/graphql'
});

const apolloProvider = new VueApollo({
  defaultClient: client
});

new Vue({
  render: h => h(App),
  apolloProvider //injecting apolloProvider to root vue instance
}).$mount('#smt-region-app')