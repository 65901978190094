<template>
  <div :class="drawerClasses">
    <button class="close" v-on:click="$emit('close')">
      <span class="btn-text">Close</span>
    </button>

    <figure class="drawer__image">
      <img
        v-if="drawerData.featuredImage"
        width="625"
        height="415"
        class="drawer__image-img"
        :src="drawerData.featuredImage.node.sourceUrl"
        :alt="drawerData.title"
        :srcset="drawerData.featuredImage.node.srcSet"
        sizes="(min-width: 768px) 470px, calc(100vw - 6em)"
      />
    </figure>

    <div class="drawer__content">
      <h3 class="drawer__title">{{ drawerData.title }}</h3>

      <div class="drawer__excerpt" v-html="drawerData.excerpt"></div>

      <a class="drawer__more" v-if="drawerData.link" :href="drawerData.link"
        >Find out More</a
      >

      <div class="part-of-route" v-if="drawerData.routes">
        Part of the:
        <img
          v-for="route in this.drawerData.routes.nodes"
          :key="route.id"
          width="156"
          height="120"
          :src="route.routeTaxonomyLogo.routeLogoAlt.link"
          class="route-logo-img"
          :alt="route.routeTaxonomyLogo.routeLogoAlt.name"
          :srcset="route.routeTaxonomyLogo.routeLogoAlt.srcSet"
          sizes="(max-width: 156px) 100vw, 156px"
        />
        <img
          v-if="drawerData.destinationLogo.destinationLogo"
          width="156"
          height="120"
          :src="drawerData.destinationLogo.destinationLogo.link"
          class="route-logo-img"
          :alt="`${drawerData.title} Destination Logo`"
          :srcset="drawerData.destinationLogo.destinationLogo.srcSet"
          sizes="(max-width: 156px) 100vw, 156px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ALL_DRAWER_DATA } from "../graphql";

export default {
  props: {
    id: {},
    type: {},
    active: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    id(newData, oldData) {
      if (!newData) {
        // if null
        setTimeout(() => {
          // this insures we dont lose the computed drawer data when the id is set to null and we get to see it transition out
          this.activeId = null;
        }, 600);
      } else {
        this.activeId = newData;
      }
    }
  },
  data: function() {
    return {
      //   isActive: false,
      contentType: "regions",
      regions: null,
      towns: null,
      activeId: ""
    };
  },
  apollo: {
    regions: {
      query: ALL_DRAWER_DATA
    },
    towns: {
      query: ALL_DRAWER_DATA
    }
  },
  computed: {
    drawerData: function() {
      if (
        this.activeId &&
        this[this.type + "s"] &&
        this[this.type + "s"].nodes
      ) {
        const data = this[this.type + "s"].nodes.find(
          item => item.slug === this.activeId
        );
        if (data) return data;
      }

      return {};
    },
    drawerClasses() {
      const classes = ["drawer", { "is-active": this.id }];

      if (this.drawerData.routes)
        classes.push(this.drawerData.routes.nodes[0].slug);

      return classes;
    }
  }
};
</script>

<style scoped lang="scss">
.drawer {
  background: #fff;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 90vw;
  //   font-size: min(2vw, 1em);
  height: 100%;
  //display: none;
  overflow: auto;
  transition: transform 0.3s ease-in;
  transform: translateX(-100%);
}

@media screen and (min-width: 768px) {
  .drawer {
    width: min(40vw, 600px);
  }
}

.drawer.is-active {
  transition: transform 0.3s ease-out;
  transform: translateX(0);
  box-shadow: 5px 0px 36px 5px rgb(0 0 0 / 40%);
}

@media screen and (min-width: 1800px) {
  .drawer,
  .drawer.is-active {
    transition: transform 0.5s ease-out;
  }
}

.drawer__image {
  max-width: 100%;
  height: auto;
  margin: 0;
}

.drawer__image-img {
  display: block;
  width: 100%;
  height: 25em;
  object-fit: cover;
  max-height: min(28vh, 44vw);

  @media screen and (min-width: 768px) and (max-width: 1799px) {
    max-height: min(26vh, 21vw);
  }

  @media screen and (min-width: 1800px) {
    max-height: none;
  }
}

.close {
  position: absolute;
  right: 0;
}
@media screen and (min-width: 1800px) {
  .close {
    display: none;
  }
}
</style>
