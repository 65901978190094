<template>
  <Map />
</template>

<script>
import Map from "./components/Map.vue";

export default {
  name: "app",
  components: {
    Map
  },
  data: function() {
    return {};
  }
};
</script>

<style scoped></style>
