//co-orginates of the center fo the marker dots when the zoom map is 1200 x 800
export default {
  bega: {
    x: 587,
    y: 370
  },
  bermagui: {
    x: 705,
    y: 197
  },
  mallacoota: {
    x: 1171,
    y: 288
  },
  inverloch: {
    x: 105,
    y: 665
  },
  sydney: {
    x: 777,
    y: 201
  },
  canberra: {
    x: 425,
    y: 551
  },
  wollongong: {
    x: 762,
    y: 401
  },
  kiama: {
    x: 726,
    y: 574
  },
  ulladulla: {
    x: 628,
    y: 635
  },
  nowra: {
    x: 715,
    y: 309
  },
  "jervis-bay": {
    x: 774,
    y: 442
  },
  "batemans-bay": {
    x: 645,
    y: 336
  },
  narooma: {
    x: 620,
    y: 650
  },
  merimbula: {
    x: 620,
    y: 464
  },
  eden: {
    x: 625,
    y: 602
  },
  yarrawonga: {
    x: 741,
    y: 463
  },
  cobram: {
    x: 538,
    y: 382
  },
  rhyll: {
    x: 528,
    y: 380
  },
  bendigo: {
    x: 414,
    y: 404
  },
  echuca: {
    x: 527,
    y: 435
  },
  walhalla: {
    x: 304,
    y: 408
  },
  "lakes-entrance": {
    x: 702,
    y: 364
  },
  "wilsons-promontory": {
    x: 266,
    y: 773
  },
  traralgon: {
    x: 332,
    y: 483
  },
  albury: {
    x: 813,
    y: 434
  },
  melbourne: {
    x: 345,
    y: 315
  },
  tathra: {
    x: 651,
    y: 401
  },
  buchan: {
    x: 731,
    y: 244
  },
  sale: {
    x: 466,
    y: 460
  }
};
