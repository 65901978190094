<template>
<div class="region-app">
  <MapDrawer :id="activeDrawerId"  :type="activeDrawerType" @close="closeDrawer()" />
  <div class="map-wrapper">
    <!-- <div :class="['svg-wrapper', svgWrapperClass()]"> -->
      <button v-if="displayZoomBtn" class="back-button" @click="zoomOut"><span class="btn-text">{{ this.backButtonLabel }}</span></button>

      <div
        v-if="displayOverlay"
        class="map-overlay"
        @click="initialZoomIn"
      >
        <div class="map-overlay__content">
          <p>Zoom In</p>
        </div>
      </div>
    <div
      :class="['map-container', {'is-zooming': this.isZooming}]"
      v-hammer:pan.horizontal="onPanHorizontal"
      v-hammer:pan="onPan"
      v-hammer:panend="onPanEnd"
      @click="handleSVGClick($event)"
    >
      <!-- v-hammer:pinch="onPinch"
      v-hammer:pinchmove="onPinch"
      v-hammer:pinchend="onPinchEnd" -->
      
      <MapSVG></MapSVG>
    </div>
  </div>
  </div>
</template>

<script>
//import gsap from "gsap";
import REGION_SVG_DATA from './region-svg-data' // this is technically not needed anymore - todo updpate
import TOWN_SVG_DATA from './town-svg-data'
import MapDrawer from "./Drawer.vue";
import MapSVG from './svg/Map.svg';


export default {
    components: {
    MapDrawer,
    MapSVG
  },
  data: function() {
    return {
      x: 0,
      y: 0,
      scale: null,
      displayDefaultWidth: 1800,
      displayDefaultHeight: 1200,
      zoomMapDefaultWidth: 345, //300,
      zoomMapDefaultHeight: '',

      //imgStyle: { transform: "0.4s ease-out 0s" },
  

      container: null,
      containerWidth: null,
      containerHeight: null,


      displayOverlay: true,
      isZooming: true,
    //   regionMapActive: false,

      svg: null,
      regionEls: {},
      backButtonLabel: 'Zoom Out',
      viewBox: '0 0 1800 1200',
      zoomedInToId: null,

      activeDrawerId: null,
      activeDrawerType: 'region',
    };
  },

  mounted() {
    this.container = document.querySelector(".map-container");
    this.svg = this.container.querySelector("svg");

    // save region groups to be used by gsap
    const regionEls = this.svg.querySelectorAll(".region-area")
    regionEls.forEach(el => this.regionEls[el.dataset.region] = el)

    this.zoomMapDefaultHeight = this.displayDefaultHeight/(this.displayDefaultWidth/this.zoomMapDefaultWidth);


    this.$nextTick(function() {

      window.addEventListener("resize", this.resizeContainer);

      /*
      this.container.addEventListener('wheel', e => {
        console.log("zooming", e);
        this.scale = this.clampScale(this.scale + (e.wheelDelta / 800));
        const x = this.clamp(this.x,this.rangeMinX, this.rangeMaxX );
        const y = this.clamp(this.y, this.rangeMinY, this.rangeMaxY );
        this.updateImagePosition(x, y, this.scale);
      }, false);

      */

    this.container.addEventListener('wheel', e => {

      console.log("scrolling wheen", e);
        
      const currX = this.x;
      const currY = this.y;
      let x = this.clamp(currX + e.wheelDeltaX/2, this.rangeMinX, this.rangeMaxX);
      let y = this.clamp(currY + e.wheelDeltaY/2, this.rangeMinY, this.rangeMaxY);

        if(this.containerWidth < 1800 && y > this.rangeMinY && y < this.rangeMaxY){
          e.preventDefault();
          e.stopPropagation();
          console.log("move image")
          this.updateImagePosition(x, y, this.scale);
          this.y = y;
        }
      }, false);



      this.resizeContainer();

    });

    const siteUrl = typeof pageInfo !== 'undefined' ? pageInfo.site_url : ''
    const routeLinks = this.svg.querySelectorAll('.route-link')
    routeLinks.forEach( el => el.setAttribute('xlink:href', siteUrl+el.getAttribute('xlink:href')))
  },
  methods: {
    onPanHorizontal() {
      console.log("on onPanHorizontal");
    },
    onPan(ev) {
      console.log("on onPan", ev, ev.deltaX, ev.deltaY);
      // this.x = this.x + ev.deltaX;
      // this.y = this.y + ev.deltaY;

      const currX = this.x;
      const currY = this.y;
      let x = this.clamp(currX + ev.deltaX, this.rangeMinX, this.rangeMaxX);
      let y = this.clamp(currY + ev.deltaY, this.rangeMinY, this.rangeMaxY);
      console.log(currX, currY);
      //     displayImageCurrentX = clamp(displayImageX + ev.deltaX, rangeMinX, rangeMaxX);
      // displayImageCurrentY = clamp(displayImageY + ev.deltaY, rangeMinY, rangeMaxY);
      //     updateDisplayImage(displayImageCurrentX, displayImageCurrentY, displayImageScale);

      this.updateImagePosition(x, y, this.scale);
    },
    onPanEnd(ev) {
      console.log("on onPanEnd", ev);
      this.x = this.clamp(this.x + ev.deltaX, this.rangeMinX, this.rangeMaxX);
      this.y = this.clamp(this.y + ev.deltaY, this.rangeMinY, this.rangeMaxY);
    },
    // onPinch(ev) {
    //   console.log("on pinch", ev);
    //   const scale = this.clampScale(ev.scale * this.scale);
    //   const x = this.clamp(this.x + ev.deltaX, this.rangeMinX, this.rangeMaxX);
    //   const y = this.clamp(this.y + ev.deltaY, this.rangeMinY, this.rangeMaxY);
    //   this.updateImagePosition(x, y, scale);

    //   this.scale = scale;
    // },
    // onPinchEnd(ev) {
    //   console.log("on pinch end");
    //   this.scale = this.clampScale(ev.scale * this.scale);
    // },
    updateImagePosition(x, y, scale, transition = false) {
      const style = {
        transform: `translateX(${x}px) translateY(${y}px) translateZ(0px) scale(${scale}) translate3d(0, 0, 0)`,
      };

      if(transition){
        style.transition = `transform 0.6s ease-out`
          
        setTimeout(() => {
            //this.isZooming = false;
            console.log("remove css transiiotn");
        }, 600);
      }

      //this.imgStyle = style;

      Object.entries(style).map( ([prop, value]) => {
        this.svg.style[prop] = value;
      })
    },
    clamp(value, min, max) {
      return Math.min(Math.max(min, value), max);
    },


    resizeContainer() {
      this.containerWidth = this.container.offsetWidth;
      this.containerHeight = this.container.offsetHeight;

      if (this.displayOverlay) {
        this.scale = this.zoomOutScale; // zoomed out initially;

        if (this.scale >= 1) {
          //if we dont need to zoom
          this.scale = 1;
          this.isZooming = false;
          this.displayOverlay = false;
          console.log("dont need to zoom");
          //this.setFontSizing();
        }
        console.log("scalling init " + this.scale);
      } else {
        this.scale = this.zoomedInScale;
        //this.setFontSizing();
      }

      const x = this.clamp(this.x, this.rangeMinX, this.rangeMaxX);
      const y = this.clamp(this.y, this.rangeMinY, this.rangeMaxY);
      this.updateImagePosition(x, y, this.scale);

    },

    clampScale(newScale) {
      return this.clamp(newScale, this.zoomOutScale, 1);
    },
    initialZoomIn() {
      console.log("click zoom)");
      this.isZooming = true;
      this.displayOverlay = false;
      this.scale = this.zoomedInScale;
      this.updateImagePosition(this.x, this.y, this.scale);

      setTimeout(() => {
        this.isZooming = false;
        console.log("remove css transiiotn");
      }, 600);

      //this.setFontSizing();
    },
    initialZoomReset() {
      console.log("click zoom back to mobile orig");
      this.displayOverlay = true;
      this.scale = this.zoomOutScale;
      this.isZooming = true;
      this.x = 0;
      this.y = 0;
      this.updateImagePosition(this.x, this.y, this.scale);

      setTimeout(() => {
        this.isZooming = false;
        console.log("remove css transiiotn");
      }, 600);
    },
    zoomOut() {
      if(this.zoomedInToId)
        this.goBackTofullMap();
      else
        this.initialZoomReset(); //ie this shoud only hapen on mobile ie <1800px
    },
    activateDrawer(id, type = 'region'){
      this.activeDrawerId = id;
      this.activeDrawerType = type;
      //this.svg.querySelectorAll('.region-label.marker-is-active').forEach(elem => elem.classList.remove("marker-is-active"))

      if(type=='region' && id) {
        const regionLabel = this.regionEls[id].querySelector(`.region-label`);
        if(regionLabel)
          regionLabel.classList.add('marker-is-active')
      }
    },
    zoomTo(id, focusTown=null) {
        //center
    //     setTimeout(()=>{
    //            let x = this.clamp(275, this.rangeMinX, this.rangeMaxX);
    //   let y = this.clamp(157, this.rangeMinY, this.rangeMaxY);
    //   this.updateImagePosition(x, y, this.scale, true);
    //   this.x = x;
    //   this.y = y;
    //     }, 600);
      //

    //   console.log("viewport change 2", $event.target, $event.toElement, $event.srcElement, lili);
      console.log("viewport change 2"+id);
      //   const newView = `800 500 600 600`
    //   let newView = `450 350 200 220`;

    if(!this.zoomedInToId || this.zoomedInToId.slug !== id){ // we're not already zoomined into the region (eg someone just clicked on the "region label" so only the draw needs to be activated)

      //OLD cool zooming functionality
      /*
      let newView = `${REGION_SVG_DATA[id].x} ${REGION_SVG_DATA[id].y} ${this.zoomMapDefaultWidth} ${this.zoomMapDefaultHeight}`; // aspect ratio divide by 6

      if(REGION_SVG_DATA[id].width) {

        const height = this.displayDefaultHeight/(this.displayDefaultWidth/REGION_SVG_DATA[id].width);

        newView = `${REGION_SVG_DATA[id].x} ${REGION_SVG_DATA[id].y} ${REGION_SVG_DATA[id].width} ${height}`; // aspect ratio divide by 6
        console.log('cusotm width', newView)
      }

      */
        

      if(this.containerWidth >= 1800){
        /*
        //   newView = '300 350 400 220';
        let width = this.zoomMapDefaultWidth;
        // let drawerOffset = 600 / (1200 / this.zoomMapDefaultWidth);

        //newView = `${REGION_SVG_DATA[id].x - drawerOffset} ${REGION_SVG_DATA[id].y} ${this.zoomMapDefaultWidth + drawerOffset} ${this.zoomMapDefaultHeight}`; // `-150 same +150 same` (drawer width is 600)
        if(REGION_SVG_DATA[id].width) {
          width = REGION_SVG_DATA[id].width
        }

        const drawerOffset = 600 / (1200 / width);
        const height = this.displayDefaultHeight/(this.displayDefaultWidth/width);
        newView = `${REGION_SVG_DATA[id].x - drawerOffset} ${REGION_SVG_DATA[id].y} ${width+drawerOffset} ${height}`; // aspect ratio divide by 6
        

        console.log('new view'+id, newView)

        */
        console.log("do nothig")


      }else{
        this.svg.setAttribute("viewBox", '600 200 1200 800'); 

        if(focusTown){

            console.log(TOWN_SVG_DATA[focusTown])
           // let x = this.clamp(this.rangeMaxX- this.zoomedInToId.x*this.scale + (this.containerWidth - 300*this.scale)/2, this.rangeMinX, this.rangeMaxX);
            //inverlock
            this.x = this.clamp( (1200 / 2 - 80 + this.containerWidth ), this.rangeMinX, this.rangeMaxX);
            this.y = this.clamp((650 - 800 - this.containerHeight / 2), this.rangeMinY, this.rangeMaxY);

            //bermagui
            this.x = this.clamp( (1200 / 2 - TOWN_SVG_DATA[focusTown].x - (this.containerWidth / 2) ), this.rangeMinX, this.rangeMaxX);
            this.x = this.clamp( (1200 / 2 - TOWN_SVG_DATA[focusTown].x )*1.5 * this.scale, this.rangeMinX, this.rangeMaxX);
            this.y = this.clamp((TOWN_SVG_DATA[focusTown].y - 800 - this.containerHeight / 2), this.rangeMinY, this.rangeMaxY);
            this.y = this.clamp((800 / 2 - TOWN_SVG_DATA[focusTown].y)*1.5 * this.scale, this.rangeMinY, this.rangeMaxY);
            console.log(`reseting reg map to ${ (1200 / 2 - TOWN_SVG_DATA[focusTown].x )*1.5} - cw/2=${this.containerWidth / 2 } =  ${(1200 / 2 - TOWN_SVG_DATA[focusTown].x - (this.containerWidth / 2) )}, ${TOWN_SVG_DATA[focusTown].y - 800} - ch=${this.containerHeight / 2}`)
            console.log(`reseting y  ${(800 / 2 - TOWN_SVG_DATA[focusTown].y*1.5)} - ch=${this.containerHeight / 2}`)
        }else{
            this.x = this.y = 0;
        }

  
        this.updateImagePosition(this.x, this.y, this.scale);

      }

    //   let transitionDuration = 0.6;
    //   if(this.zoomedInToId) {
    //     transitionDuration = 0;
    //   }

        // gsap.to(this.svg, {
        //     attr: { viewBox: newView },
        //     duration: transitionDuration,
        //     ease: "circ"
        // });
        


        if(this.zoomedInToId) {
            console.log('hide prev zoom '+this.zoomedInToId.slug)
            // //gsap.to(`.region-area-${this.zoomedInToId.slug}`, {
            // gsap.to(this.regionEls[this.zoomedInToId.slug], {
            // // attr: { viewBox: newView },
            //     opacity: 0,
            //     visibility: 'hidden',
            //     duration: transitionDuration,
            // // delay: 1.4,
            //     ease: "circ"
            // });

            this.regionEls[this.zoomedInToId.slug].style.visibility = "hidden";
            this.regionEls[this.zoomedInToId.slug].style.opacity = 0;

           // gsap.to(`.region-area-${id}`, {
            // gsap.to(this.regionEls[id], {
            //     // attr: { viewBox: newView },
            //     opacity: 1,
            //     visibility: 'visible',
            //     duration: transitionDuration,
            //     //delay: 0.4,
            //     ease: "circ"
            // });

        }
        //else{

            // gsap.to(this.regionEls[id], {
            //     // attr: { viewBox: newView },
            //     opacity: 1,
            //     visibility: 'visible',
            //     duration: transitionDuration,
            //     //delay: 0.4, //delay because we are zooming in
            //     ease: "circ"
            // });
        //}

        this.regionEls[id].style.opacity = 1;
        this.regionEls[id].style.visibility = "visible";
    }else{
      console.log('dont need to');
    }

       if(this.containerWidth >= 1800 || this.activeDrawerId !== null || (this.zoomedInToId !== null && this.zoomedInToId.x === REGION_SVG_DATA[id].x ) ){
        this.activateDrawer(id);
       }

        this.zoomedInToId = REGION_SVG_DATA[id];
      
           
    },
    closeDrawer() {
        this.activeDrawerId = null;
        this.svg.querySelectorAll('.marker-is-active').forEach(elem => elem.classList.remove("marker-is-active"))
    },
    setFontSizing() {
      console.warn(REGION_SVG_DATA)
         console.dir(REGION_SVG_DATA)

        Object.values(REGION_SVG_DATA).forEach(region=>{

        let fontSize = '';
        const width = region.width ? region.width : this.zoomMapDefaultWidth;
        if(this.containerWidth >= 1800){
          const drawerOffset = 600 / (1200 / width);
          fontSize = 13/(1800 /(width + drawerOffset) )
        }else{
          //fontSize = 13 / (this.rangeX / width);
          fontSize = 13 / (1800 / width) * 1.7; //not sure about this 2.2??
        }
        console.log(`setting fornt for ${region.slug} to ${fontSize }`)
          // //gsap.set(`.region-area-${region.slug}`, {
          // gsap.set(this.regionEls[region.slug], {
          //   fontSize: `${fontSize}px` // reduce font size relative to zoom width for marker star sizing
          // });
          this.regionEls[region.slug].style.fontSize = `${fontSize}px`
        })
   
    },

    goBackTofullMap() {
        console.log("go back to full map");
        // gsap.to(this.svg, {
        //   attr: { viewBox: this.viewBox },
        //   duration: 0,
        //   ease: "circ"
        // });
       this.svg.setAttribute("viewBox", this.viewBox); 


        // remove active marker class
        this.svg.querySelectorAll('.marker-is-active').forEach(elem => elem.classList.remove("marker-is-active"))
 
        //this.$emit('click:location', {drawer: false})
        this.activateDrawer(null);


    //    let x = this.clamp(275, this.rangeMinX, this.rangeMaxX);
       let x = this.clamp(this.rangeMaxX- this.zoomedInToId.x*this.scale + (this.containerWidth - 300*this.scale)/2, this.rangeMinX, this.rangeMaxX);
    //   let y = this.clamp(157, this.rangeMinY, this.rangeMaxY);
      let y = this.clamp(this.rangeMaxY- this.zoomedInToId.y*this.scale + (this.containerHeight - 200*this.scale)/2, this.rangeMinY, this.rangeMaxY);
      this.updateImagePosition(x, y, this.scale);

      this.x = x;
      this.y = y;
      
      this.zoomedInToId = null;

      // const tl = gsap.timeline();
      // tl.to(".region-area", {
      //   opacity: 0,
      //   duration: 0,
      //   ease: "circ"
      // });
      // tl.set(".region-area", {
      //   visibility: 'hidden',
      // });

      Object.values(this.regionEls).forEach(el => {
        el.style.opacity = 0;
        el.style.visibility = "hidden";
      })

    },
    handleSVGClick(e){
        console.warn(e.target);
      if (e.target.matches('.hs')) {
    //   if (e.target.id.startsWith('hs-')) {

        console.log('Got hs element', e.target.id, e.target)
                console.dir(e.target)

        // const [, hsType, slug] = e.target.id.match(/^hs-([^-]+)-(.+)/)
        const [, hsType, slug] = e.target.dataset.hsTarget.match(/^([^-]+)-(.+)/)

        this.svg.querySelectorAll('.marker-is-active').forEach(elem => elem.classList.remove("marker-is-active"))

        if(hsType === 'town') {
            e.target.parentNode.classList.add('marker-is-active')
            this.activateDrawer(slug, hsType)
        } else {
            this.zoomTo(slug, e.target.dataset.focusTown)
        }
      }
    },
  },
  computed: {
    rangeX() {
        // if(this.regionMapActive){
        //      return Math.max(
        //         0,
        //         Math.round(this.zoomMapDefaultWidth * this.scale) - this.containerWidth
        //     );
        // }

      return Math.max(
        0,
        Math.round(this.displayDefaultWidth * this.scale) - this.containerWidth
      );
    },
    rangeY() {
      return Math.max(
        0,
        Math.round(this.displayDefaultHeight * this.scale) -
          this.containerHeight
      );
    },
    rangeMaxX() {
        //    if(this.regionMapActive){
        //                    return 425 * this.scale;

        // }
      return Math.round(this.rangeX / 2);
    },
    rangeMinX() {
        // if(this.regionMapActive){
        //     //return 1700
        //     return this.rangeMaxX - this.rangeX;
        //                 // return (420 +300)* this.scale ;

        // }


      return 0 - this.rangeMaxX;
    },
    rangeMaxY() {
      return Math.round(this.rangeY / 2);
    },
    rangeMinY() {
      return 0 - this.rangeMaxY;
    },
    zoomOutScale() {
      return this.containerWidth / this.displayDefaultWidth;
    }, //so full width fits within container
    // zoomedInScaleO() {
    //   return (this.containerHeight / this.displayDefaultHeight) * 1.5 > 1
    //     ? 1
    //     : this.containerHeight / this.displayDefaultHeight;
    // },
    //so full width fits within container
    zoomedInScale() {
      //based on height
      const h = (this.containerHeight / this.displayDefaultHeight) * 1.9;
      return h > 1 ? 1 : h <= this.zoomOutScale ? this.zoomOutScale : h;
    },
    displayZoomBtn() {
       return (this.zoomedInToId || this.containerWidth < 1800) && !this.displayOverlay
    }
  }
};
</script>

<style lang="scss">
.region-app {
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
    overflow: hidden;
}
.map-container {

  height: 1200px;
  overflow: hidden;
  left: 0;

  display: grid;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 1799px) {
  .map-container {
    max-height: 80vh;
  }
}

.map-wrapper {

  background: linear-gradient(0deg, #5c88da 50%, #3c3c3c 50%);

      max-width: 1800px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 1799px) {
        &:hover {
            cursor: move;
        }
    }
}

.region-explore-btn {
  @media screen and (min-width: 1800px) {
  display: none
}
}

.map-svg {
  /*max-width: 100%;*/
  height: auto;
  width: 100%;
}

.map-container svg {
  font-size: 13px; //for the marker star sizing
  transform: translateZ(0);
  backface-visibility: hidden;
}

.map-container.is-zooming svg {
  transition: 0.6s ease-out 0s;
}

.label {
  font-size: 2em;
  font-weight: bold;
}

.map-overlay {
  background: rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  cursor: zoom-in;
  z-index: 10;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: grid;
  align-content: center;
  font-size: 2em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.map-overlay p {
  border: solid 3px white;
  padding: 0.5em 1em;
}

// #zoom {
//     opacity: 0;
//     visibility: hidden;
//     /* border: solid 1px red; */
//     transition: opacity 0.6s ease-out, visibility 0.6s ease-out;
// }

// #zoom.active {
//     visibility: visible;
//     opacity: 1;
// }

.back-button {
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 1;
}

.hs {
    cursor: pointer;
     fill: transparent;
    //stroke: blue;
    stroke-width: 1
}

/* .map-overlay .map-overlay__content{
  position: relative;
}

.map-overlay .map-overlay__content span {
 width: 100%;
    height: 0x;
    border-bottom: 2px solid white;
    transform: translateY(-20px) translateX(5px) rotate(27deg); 
    position: absolute;
    display: block;
}

.map-overlay .map-overlay__content span:nth-of-type(1) {
    border-color: green;
    transform: translateY(0) translateX(0px) rotate(27deg); 
}
.map-overlay .map-overlay__content span:nth-of-type(2) {
    border-color: red;
    transform: translateY(0) translateX(0px) rotate(-27deg); 
}
.map-overlay .map-overlay__content span:nth-of-type(3) {
    border-color: orange;
    transform: translateY(0) translateX(5px) rotate(27deg); 
} */

.marker-circle {
    stroke: white;
//     animation-name: markerpulse;
//     animation-duration: 2s;
//   animation-iteration-count: infinite;



    &:nth-of-type(3n + 2) {
    animation-delay: 0.25s;

  }

      &:nth-of-type(3n) {
    animation-delay: 0.5s;

  }
}

.region-label {
    .underline {
        transition: stroke-width 0.05s ease;
    }

    &:not(.marker-is-active, :hover) {
        .underline {
            stroke-width: 0px;
        }
    }
}

.marker-group {
    cursor: pointer;

    &.inland {
        .marker-underline {
            fill: pink;
        }
    }

   &:nth-of-type(3n + 2) .marker-circle{
    animation-delay: 0.25s;
  }

  &:nth-of-type(3n + 3) .marker-circle{
    animation-delay: 0.5s;
  }

    &:not(.marker-is-active) {

    //&:not(.active):not(&:hover) {
        .marker-underline {
            //width: 0; // this was for rects, now theyre strokes
            stroke-width: 0;
        }
    }


        .marker-underline {

            //transition: width 0.5s ease-out; // this was for rects, now theyre strokes
             transition: stroke-width 0.05s ease-out;
        }




    .marker-star {
        //display: none;
    
        polygon,
        path {
            transition: transform 0.2s ease-out;
            // transform: translateX(1%) translateY(1.5%);

            // &:nth-child(4) {
            //     transform: translateX(-1%) translateY(1.5%);
            // }

            // &:nth-child(2) {
            //    transform: translateX(-1%) translateY(-1.5%);
            // }

            // &:nth-child(3) {
            //     transform: translateX(1%) translateY(-1.5%);
            // }

              transform: translateX(1em) translateY(1em) translate3d(0, 0, 0);

            &:nth-child(4) {
                transform: translateX(-1em) translateY(1em) translate3d(0, 0, 0);
            }

            &:nth-child(2) {
               transform: translateX(-1em) translateY(-1em) translate3d(0, 0, 0);
            }

            &:nth-child(3) {
                transform: translateX(1em) translateY(-1em) translate3d(0, 0, 0);
            }

            //  transform: translateX(12px) translateY(12px);

            // &:nth-child(4) {
            //     transform: translateX(-12px) translateY(12px);
            // }

            // &:nth-child(2) {
            //    transform: translateX(-12px) translateY(-12px);
            // }

            // &:nth-child(3) {
            //     transform: translateX(12px) translateY(-12px);
            // }
        }
    }

    &.marker-is-active,
    &:hover {
        .marker-star {
            //display: block;
            polygon,
            path {
                transform: translateX(0) translateY(0) translate3d(0, 0, 0);
            }
        }

        circle {
            display: none;
        }
    }
}

.region-area {
    opacity: 0;
    visibility: hidden;
}

.guides {
  display: none;
}

//test center guides
/*
.map-container {
    position: relative;

    &::before {
        content: "";
    position: absolute;
    height: 100%;
    background: yellow;
    width: 1px;
    left: 50%;
    display: block;
    z-index: 99;
    }
    &::after {
        content: "";
    position: absolute;
    width: 100%;
    background: yellow;
    height: 1px;
    top: 50%;
    display: block;
    z-index: 99;
    }
}
*/


@keyframes markerpulse {
  0%   {stroke-width: 0;}
50% {stroke-width: 4;}
  100% {stroke-width: 0;}
}
</style>
