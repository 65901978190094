import { gql } from "apollo-boost";

export const ALL_DRAWER_DATA = gql`
  query RTQuery {
    regions(first: 100) {
      nodes {
        id
        title
        link
        slug
        excerpt
        featuredImage {
          node {
            sourceUrl
            srcSet(size: MEDIUM_LARGE)
          }
        }
        destinationLogo {
          destinationLogo {
            srcSet
          }
        }
        routes {
          nodes {
            id
            databaseId
            name
            slug
            routeTaxonomyLogo {
              routeLogoAlt {
                link
                srcSet(size: MEDIUM)
              }
            }
          }
        }
      }
    }
    towns(first: 100) {
      nodes {
        id
        title
        link
        slug
        excerpt
        featuredImage {
          node {
            sourceUrl
            srcSet(size: MEDIUM_LARGE)
          }
        }
        destinationLogo {
          destinationLogo {
            srcSet
          }
        }
        routes {
          nodes {
            name
            id
            slug
            databaseId
            routeTaxonomyLogo {
              routeLogoAlt {
                link
                srcSet(size: MEDIUM)
              }
            }
          }
        }
      }
    }
  }
`;
